import React from 'react';
import { Card, Col, Row, Tag, Typography, List, Divider } from 'antd';
import WordCloud from 'react-d3-cloud';
import BarChart from './Bar';

const { Title, Text } = Typography;

function ModalAi({ data }) {
  const wordCloudData = data ? [
    ...Object.keys(data.positive_words).map(key => ({
      text: key,
      value: data.positive_words[key],
      color: 'green',
    })),
    ...Object.keys(data.negative_words).map(key => ({
      text: key,
      value: data.negative_words[key],
      color: 'red',
    }))
  ] : [];

  const emotionsData = data ? Object.keys(data.emotions).map(key => ({
    type: key,
    value: data.emotions[key],
  })) : [];

  const auditHighlights = data ? Object.values(data.key_sentences) : [];

  const positiveKeywords = data ? Object.keys(data.positive_words) : [];
  const negativeKeywords = data ? Object.keys(data.negative_words) : [];

  const styles = {
    scrollableList: {
      height: '500px',
      overflowY: 'auto',
      overflowX: 'hidden',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      backgroundColor: '#fff',
    },
    tag: {
      padding: '5px 10px',
      display: 'inline-block',
      margin: '5px',
      borderRadius: '10rem',
    },
    barStyle: {
      
      margin: '3px ',
      maxHeight: '1.5rem',
    },
  };

  
  const wordColor = word => word.color;
  return (
    <Card title="" style={{ border: 'none' }}>
      <Row gutter={16}>
        <Col span={7}>
          <List
            header={<Title level={4}>Audit Highlights</Title>}
            bordered
            dataSource={auditHighlights}
            renderItem={item => (
              <List.Item>
                <Text style={{textAlign:"justify"}}>{item}</Text>
              </List.Item>
            )}
            style={styles.scrollableList}
          />
        </Col>
        <Col span={11} style={styles.scrollableList}>
          <Title level={4}>Report Word Cloud</Title>
          <Divider style={{ margin: "5px" }} />
          <WordCloud
            data={wordCloudData}
            fontSize={(d) => Math.sqrt(d.value)*25}
            rotate={word => (word.value % 360)}
            fill={wordColor}
            height={200} 
            width={480}
            
          />
          <Col style={styles.barStyle} >
            <Title level={4} style={{marginTop:"3.2rem"}}>Customer Emotions</Title>
            <Divider style={{ margin: "2px" }} />
            <BarChart data={emotionsData} style={styles.barStyle}/>
          </Col>
        </Col>
        <Col span={6} style={styles.scrollableList}>
          <Title level={4}>Positive Keywords</Title>
          <Divider style={{ margin: "5px" }} />
          {positiveKeywords.map(keyword => (
            <Tag color="green" key={keyword} style={styles.tag}>{keyword}</Tag>
          ))}
          <Title level={4}>Negative Keywords</Title>
          <Divider />
          {negativeKeywords.map(keyword => (
            <Tag color="red" key={keyword} style={styles.tag}>{keyword}</Tag>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default ModalAi;
