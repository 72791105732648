import React, { useState } from "react";
import { Button, Modal, Input, Col, Row, Select } from "antd";
import axios from "axios";
import ModalAi from "./ModalAi";
import PageLoading from "./PageLoading";

const { Option } = Select;

function Home() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [apiData, setApiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("english");

  const languages = [
    "english", "Hindi", "Chinese", "Ukrainian", "Nepali", "Romanian", "Czech", "Arabic", "Korean", "Bulgarian", 
    "Thai", "Dutch", "Russian", "Japanese", "Spanish", "Italian", "German", "French", "Urdu", "Malay", 
    "Afrikaans", "Turkish", "Portuguese", "Indonesian", "Danish"
  ];

  const showModal = async () => {
    setIsModalVisible(true);
    await callApi(inputValue, selectedLanguage);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value.substring(0, 1000));
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const callApi = async (text, lang) => {
    setIsLoading(true);
    const api_url = "https://api.floorwalk.in/text_analysis";
    const token = "fw_f7575w";

    const formData = new FormData();
    formData.append("text", text);
    formData.append("lang", lang);
    formData.append("token", token);

    try {
      console.log("Sending Payload:", formData);

      const response = await axios.post(api_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setApiData(response.data);
    } catch (error) {
      console.error("Error:", error);
      setApiData({ error: "Failed to fetch data" });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <Row justify="center" align="middle">
        <Col xs={20} sm={16} md={12} lg={10}>
          <div
            style={{
              marginTop: "1.5rem",
              background: "#fff",
              padding: "30px",
              borderRadius: "8px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
              width: "100%",
            }}
          >
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 10 }}
              size="large"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Enter up to 1000 characters"
              style={{ marginBottom: "1.5rem" }}
            />

            <Row justify="space-between">
              <Button
                type="primary"
                onClick={showModal}
                disabled={inputValue === ""}
              >
                Submit
              </Button>
              <Select
                defaultValue="english"
                style={{ width: 150 }}
                onChange={handleLanguageChange}
                value={selectedLanguage}
              >
                {languages.map((language) => (
                  <Option key={language} value={language}>
                    {language}
                  </Option>
                ))}
              </Select>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal
        width={selectedLanguage === "english" ? 1100 : 500}
        title="Sentimental Analysis"
        open={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedLanguage === "english" ? (
          <ModalAi data={apiData} />
        ) : (
          <div style={{ justifyContent: "center", textAlign: "justify" }}>
            <h2>Audit Highlights</h2>
            <p>{inputValue}</p>
          </div>
        )}
      </Modal>
    </>
  );
}

export default Home;