import logo from './assets/FW_Logo.jpeg';
import './App.css';
import Home from './components/Home';



function App() {
  return (
    <div className="App">
       <img src={logo} className="App-logo" alt="logo" />
      <Home/>
    
    </div>
  );
}

export default App;
