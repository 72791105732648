import React from 'react'
import { Spin } from "antd"

function PageLoading() {
  return (
    <div>
    <div style={{padding:"25px",justifyContent:"center", alignItems:"center" , display:"flex", zIndex:9999,width:"100%" ,height:"100%",}}>
    <Spin style={{color: "#5BAFA9",fontSize:"10rem",}}  fullScreen="true" size="large" />
    </div>
    </div>
  )
}

export default PageLoading
