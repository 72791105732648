import React from 'react';
import {
  BarChart as RechartsBarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer
} from 'recharts';

const BarChart = ({ data }) => {
  return (
    <div style={{ marginTop: "0.5rem" }}>
      <ResponsiveContainer width="100%" aspect={3 / 1}>
        <RechartsBarChart width={700} height={570} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="type">
            <Label value="Emotions" offset={0} position="insideBottom" fill="#337ab7" />
          </XAxis>
          <YAxis domain={[0, 'dataMax']} tickFormatter={f => f}>
            <Label value="Values" angle={-90} position="insideLeft" fill="#337ab7" />
          </YAxis>
          <Tooltip formatter={v => v === null ? "N/A" : v} />
          <Bar dataKey="value" barSize={20} isAnimationActive={false} fill="#337ab7" />
        </RechartsBarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChart;
